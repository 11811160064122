import styled from 'styled-components';
import Image from 'next/image';
import { BASE_IMAGE } from "../../utils/base64Image";
const BrandLogo = ({ imageUrl, small = false, ...otherProps }) => {
	return (
		<BrandLogoCotainer small={small} {...otherProps}>
			<Image
				src={imageUrl}
				alt='brandlogo'
				loading='lazy'
				width={small ? '30' : '38'}
				height={small ? '30' : '38'}
				placeholder='blur'
				unoptimized
				blurDataURL={BASE_IMAGE}
				// layout='fill'
			/>
		</BrandLogoCotainer>
	);
};

type small = boolean;

const BrandLogoCotainer = styled.div<{ small: boolean }>`
	width: ${({ small }) => (small ? '30' : '40')};
	margin-right: 0.5em;
	display: flex;
	height: 100%;
	align-items: center;
	justify-content: center;
	border-radius: 12px;
	background: white;
	overflow: hidden;
	border: 1px solid #dddddd;
	img {
		height: 100%;
		width: 100%;
	}

	& > span {
		border-radius: 12px;
	}
	/* @media (min-width: 768px) {
		width: 50px;
		height: 50px;
	} */
`;

export default BrandLogo;
