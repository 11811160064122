import { createClient } from '@supabase/supabase-js';

const supabaseUrl = process.env.NEXT_PUBLIC_SUPABASE_URL;
const supabaseKey = process.env.NEXT_PUBLIC_SUPABASE_ANON_KEY;
export const supabase = createClient(supabaseUrl, supabaseKey);

//supabase signup with Google Oauth
// const getURL = () => {
// 	let url =
// 		process?.env?.NEXT_PUBLIC_SITE_URL && // Set this to your site URL in production env.
// 		process?.env?.NEXT_PUBLIC_SITE_URL && // Automatically set by Vercel.
// 		'http://localhost:3000/';

// 	// Make sure to include `https://` when not localhost.
// 	url = url.includes('http') ? url : `https://${url}`;
// 	// Make sure to including trailing `/`.
// 	url = url.charAt(url.length - 1) === '/' ? url : `${url}/`;
// 	return url;
// };

export async function signInWithGoogle(route) {
	let redirectUrl =
		process.env.NODE_ENV === 'production'
			? 'https://uiland.design/' + route
			: 'http://localhost:3000/' + route;

	const { data, error } = await supabase.auth.signInWithOAuth({
		provider: 'google',
		options: {
			redirectTo: redirectUrl,
		},
	});
	return data;
}

export async function signInWithFigma(route) {
	let redirectUrl =
		process.env.NODE_ENV === 'production'
			? 'https://uiland.design/' + route
			: 'http://localhost:3000/' + route;

	const { data, error } = await supabase.auth.signInWithOAuth({
		provider: 'figma',
		options: {
			redirectTo: redirectUrl,
		},
	});
	return data;
}

//    get all screens
export async function getAllScreens() {
	let { data: Screens, error } = await supabase
		.from('Screens')
		.select('*')
		.order('created_at', { ascending: false });
	// Screens.forEach((res)=>{
	//   return res['user'] = "a4f0bf66-2a36-4b4f-bf8b-b082cf9aa5c4"
	// })

	return Screens;
}



//get web screens
export async function getAllWebScreens() {
	let { data: Screens, error } = await supabase
		.from('webScreens')
		.select('*')
		.order('created_at', { ascending: false });
	// Screens.forEach((res)=>{
	//   return res['user'] = "a4f0bf66-2a36-4b4f-bf8b-b082cf9aa5c4"
	// })

	return Screens;
}



export async function getAllNamesInScreens() {
	let { data: Screens, error } = await supabase
		.from('Screens')
		.select('name,id,logo,description')
		.order('created_at', { ascending: false });
	// Screens.forEach((res)=>{
	//   return res['user'] = "a4f0bf66-2a36-4b4f-bf8b-b082cf9aa5c4"
	// })

	return Screens;
}
export async function getAllWebNamesInScreens() {
	let { data: Screens, error } = await supabase
		.from('webScreens')
		.select('name,id,logo,description')
		.order('created_at', { ascending: false });
	// Screens.forEach((res)=>{
	//   return res['user'] = "a4f0bf66-2a36-4b4f-bf8b-b082cf9aa5c4"
	// })

	return Screens;
}

//    get all screens total number
export async function getAllScreensCount() {
	const { count, error } = await supabase
		.from('Screens')
		.select('*', { count: 'exact', head: true });
	return count;
}

//    get all screens total number
export async function getAllWebScreensCount() {
	const { count, error } = await supabase
		.from('webScreens')
		.select('*', { count: 'exact', head: true });
	return count;
}

//    get all screens total number
export async function getAllFlowsCount() {
	const { count, error } = await supabase
		.from('Products')
		.select('*', { count: 'exact', head: true });
	return count;
}

//get  limited screens for view more
//    export async function getViewMoreScreens(id) {

// 	const { data, error } = await supabase
// 	.from('Screens')
// 	.select()
// 	.not('screenId', 'is', id)

// 	  return data;
// 	}

//    get all limited screens
// export async function getLimitedScreens() {
//   let { data: Screens, error } = await supabase.from("Screens").select("*").limit(1);
//
//   return Screens;
// }

//get individual screens of the newest version content

export async function updateUserProfileInfo(user, country) {
	const { error } = await supabase
		.from('profile')
		.update({ country: country })
		.eq('id', user.id);
	if (error) {
		console.log(error);
	}
}

//get user download count

export async function getDownloadCounts(user) {
	if (!user) return;
	// console.log(user);
	const { data: download, error } = await supabase
		.from('profile')
		.select('downloads')
		.eq('email', user.email);

	return download[0];
}

//get user copy count

export async function getCopyCounts(user) {
	if (!user) return;
	const { data: copy, error } = await supabase
		.from('profile')
		.select('copy')
		.eq('email', user.email);

	return copy[0];
}

export async function getCountry(brandName: string | string[]) {
	//gets country i.e Nigeria | international of the different brands

	//brand name comes with the first letter not capitalized which differs from the way it is stored in supabase

	const brand = brandName[0].charAt(0).toUpperCase() + brandName.slice(1); //capitalizes the brand name
	const { data, error } = await supabase
		.from('Screens')
		.select('country')
		.eq('name', brand)
		.limit(1);
	return data[0];
}
export async function getWebCountry(brandName: string | string[]) {
	//gets country i.e Nigeria | international of the different brands

	//brand name comes with the first letter not capitalized which differs from the way it is stored in supabase

	const brand = brandName[0].charAt(0).toUpperCase() + brandName.slice(1); //capitalizes the brand name
	const { data, error } = await supabase
		.from('webScreens')
		.select('country')
		.eq('name', brand)
		.limit(1);
	return data[0];
}

export async function getHighestversionofBrand(id) {
	const { data, error } = await supabase
		.from('screenImages')
		.select('version')
		.order('version', { ascending: false })
		.eq('screenId', id)
		.limit(1);
	if (error) {
		console.log(error);
	}

	return data[0]['version'];
}

export async function getScreensById(
	id,
	page,
	query,
	user,
	brandCountry = 'Nigeria'
) {
	let country;
	let userdata
	// const defaultVersion = await getHighestversionofBrand(id);

	if (user) {
		// get user country information
		const { data, error } = await supabase
			.from('profile')
			.select('country,event')
			.eq('id', user.id);
		
		country = data[0]['country'];
		userdata = data[0]['event'];
		if (error) {
			console.log("fish",error);
		}
	}


console.log("userg",user)
	//limit screens for unauthenticated users or international unpaid users
	if (
		!user ) {
		const { data, error } = await supabase
			.from('screenImages')

			.select('screenId,id,url,componentCategory')
			.order('url', { ascending: true })
			.limit(26)
			.eq('screenId', id)
			.eq('version', 1 || query.version );
		if (error) {
			console.log("hyg",error);
		}
		
		return data;
	}

	if (
		user && userdata !== 'subscription.create'
	) {
		const { data, error } = await supabase
			.from('screenImages')

			.select('screenId,id,url,componentCategory')
			.order('url', { ascending: true })
			.limit(50)
			.eq('screenId', id)
			.eq('version', 1 || query.version );
		if (error) {
			console.log("hyg",error);
		}
		
		return data;
	}
	
	let limit = 25;
	let limitMaxRange = page * limit;
	let limitMinRange = page * limit - limit;

	// const { data, error } = await supabase
	// 	.from('screenImages')
	// 	.select('version')
	// 	.eq('screenId', id);

	//gets unique names in the db
	// const result = data?.map((object) => object.version);
	// const uniqueResult = Array.from(new Set(result));

	//this is for the boomplay screens

	
			const { data, error } = await supabase
				.from('screenImages')
				.select('screenId,id,url,componentCategory')
				.order('order', { ascending: true })
				//   i will use this to limit the result later
				//   .limit(1)
				// .range(limitMinRange, limitMaxRange)
				.eq('screenId', id)
				.eq('version', 1);

			return data;
	
	
	// else {
	// 	const { data, error } = await supabase
	// 	.from('screenImages')
	// 	.select('screenId,id,url,componentCategory')
	// 	.order('order', { ascending: true })
	// 	//   i will use this to limit the result later
	// 	//   .limit(1)
	// 	.range(limitMinRange, limitMaxRange)
	// 	.eq('screenId', id)
	// 	.eq('version', query.version || 2);

	// return data;
	// }
}

export async function getWebScreensById(
	id,
	page,
	query,
	user,
	brandCountry = 'Nigeria'
) {
	let country;
	let userdata
	// const defaultVersion = await getHighestversionofBrand(id);

	if (user) {
		// get user country information
		const { data, error } = await supabase
			.from('profile')
			.select('country,event')
			.eq('id', user.id);
		
		country = data[0]['country'];
		userdata = data[0]['event'];
		if (error) {
			console.log("fish",error);
		}
	}




	//limit screens for unauthenticated users or international unpaid users
	if (
		!user ) {
		const { data, error } = await supabase
			.from('webScreenImages')

			.select('screenId,id,url,componentCategory')
			.order('order', { ascending: true })
			.limit(26)
			.eq('screenId', id)
			.eq('version', 1 || query.version );
		if (error) {
			console.log("hyg",error);
		}
		
		return data;
	}


	if (
		user && userdata !== 'subscription.create'
	) {
		const { data, error } = await supabase
			.from('webScreenImages')

			.select('screenId,id,url,componentCategory')
			.order('url', { ascending: true })
			.limit(50)
			.eq('screenId', id)
			.eq('version', 1 || query.version );
		if (error) {
			console.log("hyg",error);
		}
		
		return data;
	}
	
	
	let limit = 25;
	let limitMaxRange = page * limit;
	let limitMinRange = page * limit - limit;

	// const { data, error } = await supabase
	// 	.from('screenImages')
	// 	.select('version')
	// 	.eq('screenId', id);

	//gets unique names in the db
	// const result = data?.map((object) => object.version);
	// const uniqueResult = Array.from(new Set(result));

	//this is for the boomplay screens

	
			const { data, error } = await supabase
				.from('webScreenImages')
				.select('screenId,id,url,componentCategory')
				.order('order', { ascending: true })
				//   i will use this to limit the result later
				//   .limit(1)
				// .range(limitMinRange, limitMaxRange)
				.eq('screenId', id)
				.eq('version', 1);

			return data;
	
	
	// else {
	// 	const { data, error } = await supabase
	// 	.from('screenImages')
	// 	.select('screenId,id,url,componentCategory')
	// 	.order('order', { ascending: true })
	// 	//   i will use this to limit the result later
	// 	//   .limit(1)
	// 	.range(limitMinRange, limitMaxRange)
	// 	.eq('screenId', id)
	// 	.eq('version', query.version || 2);

	// return data;
	// }
}

//get individual screens of older versions
export async function getOlderScreensById(id, page, version) {
	let limit = 19;
	let limitMaxRange = page * limit;
	let limitMinRange = page * limit - limit;

	//this is for the boomplay screens
	if (
		id === 'b274aac8-8a59-4034-8456-f8a2539ddc24' ||
		id === '04b85c78-5dd6-4387-a785-a5edb72d0937'
	) {
		const { data, error } = await supabase
			.from('screenImages')
			.select('screenId,id,url')
			.order('url', { ascending: true })
			//   i will use this to limit the result later
			//   .limit(1)
			.range(limitMinRange, limitMaxRange)
			.eq('screenId', id)
			.eq('version', version || 1);

		return data;
	} else {
		const { data, error } = await supabase
			.from('screenImages')
			.select('screenId,id,url')
			.order('order', { ascending: true })
			//   i will use this to limit the result later
			//   .limit(1)
			.range(limitMinRange, limitMaxRange)
			.eq('screenId', id)
			.eq('version', version || 1);

		return data;
	}
}

//get individual screen content count
export async function getScreensByIdCount(id, version) {
	const { data, error } = await supabase
		.from('screenImages')
		.select('version')
		.eq('screenId', id);

	//gets unique names in the db
	const result = data?.map((object) => object.version);
	const uniqueResult = Array.from(new Set(result));

	//this is for the boomplay screens
	if (uniqueResult[0] === 1 && uniqueResult.length === 1) {
		const { count, error } = await supabase
			.from('screenImages')
			.select('screenId,id,url', { count: 'exact', head: true })
			.eq('screenId', id)
			.eq('version', 1);
		return count;
	} else {
		const { count, error } = await supabase
			.from('screenImages')
			.select('screenId,id,url', { count: 'exact', head: true })
			.eq('screenId', id)
			.eq('version', version || 2);
		return count;
	}
}



export async function getWebScreensByIdCount(id, version) {
	const { data, error } = await supabase
		.from('webScreenImages')
		.select('version')
		.eq('screenId', id);

	//gets unique names in the db
	const result = data?.map((object) => object.version);
	const uniqueResult = Array.from(new Set(result));

	//this is for the boomplay screens
	if (uniqueResult[0] === 1 && uniqueResult.length === 1) {
		const { count, error } = await supabase
			.from('webScreenImages')
			.select('screenId,id,url', { count: 'exact', head: true })
			.eq('screenId', id)
			.eq('version', 1);
		return count;
	} else {
		const { count, error } = await supabase
			.from('webScreenImages')
			.select('screenId,id,url', { count: 'exact', head: true })
			.eq('screenId', id)
			.eq('version', version || 2);
		return count;
	}
}



//get individual screens content(limited)
export async function getLimitedScreensById(id) {
	const { data, error } = await supabase
		.from('screenImages')
		.select('screenId,id,url')
		.order('order', { ascending: true })
		.limit(1)
		.eq('screenId', id);
	return data;
}

//supabase session
export async function getSession() {
	const {
		data: { session },
	} = await supabase.auth.getSession();
	return session;
}

//get paying user by checking the event key
export async function getProfileByEvent(user) {
	const { data, error } = await supabase
		.from('profile')
		.select('event')
		//   i will use this to limit the result later
		//   .limit(1)
		.eq('id', user.id);
	return data;
}

export async function checkSubscribedUSer(user) {
	//check if user is subscribed
	if (!user) return;
	const { data, error } = await supabase
		.from('profile')
		.select('event')
		//   i will use this to limit the result later
		//   .limit(1)
		.eq('email', user.email);
	return data[0];
}

export async function checkSubscribedUSerPlan(user) {
	//check if user is subscribed
	if (!user) return;
	const { data, error } = await supabase
		.from('profile')
		.select('plan_name')
		//   i will use this to limit the result later
		//   .limit(1)
		.eq('email', user.email);
	return data[0];
}

export async function getScreensProperties(id) {
	const { data, error } = await supabase
		.from('Screens')
		.select('*')
		.eq('id', id);

	const result = data ? data[0] : [];
	return result;
}

export async function getWebScreensProperties(id) {
	const { data, error } = await supabase
		.from('webScreens')
		.select('*')
		.eq('id', id);

	const result = data ? data[0] : [];
	return result;
}

//get Album Bookmark

export async function getBookmarks(user) {
	const { data, error } = await supabase
		.from('bulkBookmark')
		.select(
			`
       id,
       album_id (
           id,
           startScreens,
           logo,
           name,
           url,
           category,
		   country
       )
     `
		)
		.eq('user_id', user.id);

	//   i will use this to limit the result
	//   .limit(1)
	//   .eq('screenId', 'b76461af-34f9-4523-a892-b4991dfa364a')

	return data;
}

//view single Bookmark

export async function viewSingleBookmark(name) {
	const { data, error } = await supabase
		.from('singleImageBookmark')
		.select(
			`
      id,
      screen_id (
          id,
          url
      )
    `
		)
		.eq('bookmark_name', name);

	return data;
}

//delete bulkscreen
export async function DeleteScreens(id) {
	const { error, data } = await supabase
		.from('bulkBookmark')
		.delete()
		.eq('album_id', id);

	return data;
}

//add album to bookmark
export async function addBookmark(id, user) {
	const { data, error } = await supabase
		.from('bulkBookmark')
		.insert({ album_id: id, user_id: user.id })
		.select();
	return data;
}

//    get bookmark id in single bookmark
export async function getAlbumBookmarkId(user) {
	const { data, error } = await supabase
		.from('bulkBookmark')
		.select('album_id')
		.eq('user_id', user?.id);

	return data;
}

//add images to singleImageBookmark
export async function addSingleScreens(content, input, user) {
	const { data, error } = await supabase
		.from('singleImageBookmark')
		.insert({
			screen_id: content.id,
			bookmark_name: input,
			url: content.url,
			user_id: user.id,
		})
		.select();
	return data;
}

//delete singlebookmark
export async function DeleteSingleScreens(result) {
	const { error, data } = await supabase
		.from('singleImageBookmark')
		.delete()
		.eq('screen_id', result.id);

	return data;
}

//get images from singleImageBookmark
export async function getSingleScreens(user) {
	const { data, error } = await supabase
		.from('singleImageBookmark')
		.select(
			`
                screen_id,
                screenImages (
                    id,
                    url
                )
              `
		)
		.eq('user_id', user.id);
	return data;
}

//    get bookmark id in single bookmark
export async function getAllSingleBookmarkId(user) {
	const { data, error } = await supabase
		.from('singleImageBookmark')
		.select('screen_id')
		.eq('user_id', user?.id);

	return data;
}

//    get unique bookmark names in single bookmark
export async function getAllSingleBookmarkNames() {
	const { data, error } = await supabase
		.from('singleImageBookmark')
		.select('bookmark_name');
	// .eq('user_id', user.id)

	//gets unique names in the db
	const result = data?.map((object) => object.bookmark_name);
	const uniqueResult = Array.from(new Set(result));
	return uniqueResult;
}

//    get unique filters in a company
export async function getElementCategoryFilter(id) {
	const { data, error } = await supabase
		.from('screenImages')
		.select('elementCategory')
		.eq('screenId', id);

	//gets unique names in the db
	const result = data?.map((object) => object.elementCategory);
	const uniqueResult = Array.from(new Set(result));
	return uniqueResult;
}

export async function getFlowsCategoryFilter() {
	const { data, error } = await supabase
		.from('Screens')
		.select('category')
		.order('category', { ascending: true })

	//gets unique names in the db
	const result = data?.map((object) => object.category);
	const uniqueResult = Array.from(new Set(result));
	return uniqueResult;
}

export async function getWebFlowsCategoryFilter() {
	const { data, error } = await supabase
		.from('webScreens')
		.select('category')
		.order('category', { ascending: true })

	//gets unique names in the db
	const result = data?.map((object) => object.category);
	const uniqueResult = Array.from(new Set(result));
	return uniqueResult;
}

//delete account
export async function deleteAccount(user) {
	const { data, error } = await supabase
		.from('profile')
		.delete()
		.eq('id', user.id);

	return data;
}

//supabase signout
export async function signout() {
	await supabase.auth.signOut();
}

export async function getUserProfile(user) {
	if (user) {
		const { data, error } = await supabase
			.from('profile')
			.select('*')
			//   i will use this to limit the result later
			//   .limit(1)
			.eq('id', user.id);

		return data;
	}
}

//get urls from public storage
export function getImage(company, image) {
	const { data } = supabase.storage.from(company).getPublicUrl(image);

	return data;
}

//my magic tool
export async function addImagesToScreens(
	screenId: string,
	id: number,
	url: string
) {
	const { data, error } = await supabase
		.from('screenImages')
		.insert({
			screenId: screenId,
			url: url,
			order: 28800 + id,
			version: 1,
		})
		.select();

	return data;
}

export const addUserData = async (type, formdata) => {
	const { error, data } = await supabase.from(type).insert(formdata).select();

	if (error) {
		console.log(error);
	}

	return data;
};

export const addFlow = async (formdata:any) => {
	const { error, data } = await supabase.from('Products').insert(formdata).select();

	if (error) {
		console.log(error);
	}}

// track number of downloads for a user
export const numberOfDownloads = async (user) => {
	const { data, error } = await supabase.rpc('downloads', {
		user_id: user.id,
		increment_num: 1,
	});

	return data;
};

// track number of copys for a user
export const numberOfCopyImage = async (user) => {
	const { data, error } = await supabase.rpc('copy', {
		user_id: user.id,
		increment_num: 1,
	});
	return data;
};

// added version to the table
//it worked
// export const addVersion=async()=>{
// 	const { error } = await supabase
//   .from('screenImages')
//   .update({ version: 1 })
//   .eq('screenId', 'b76461af-34f9-4523-a892-b4991dfa364a')
//   return error
// }

export const getRelatedScreensByCategory = async (category, id) => {
	const { error, data } = await supabase
		.from('Screens')
		.select('*')
		.eq('category', category)
		.neq('id', id);

	if (error) {
		console.log(error);
	}

	return data;
};

export const getRelatedWebScreensByCategory = async (category, id) => {
	const { error, data } = await supabase
		.from('webScreens')
		.select('*')
		.eq('category', category)
		.neq('id', id);

	if (error) {
		console.log(error);
	}

	return data;
};

export const getRelatedScreensByID = async (id) => {
	//get category of brand with that id
	const { error, data } = await supabase
		.from('Screens')
		.select('category')
		.eq('id', id);

	if (error) {
		console.log(error);
	}

	const { category } = data[0];
	const screensData = await getRelatedScreensByCategory(category, id); //get brands that are in the same category as the brand with the id viewed

	return screensData; //this can either be an empty array or an array of screens
};

export const getRelatedWebScreensByID = async (id) => {
	//get category of brand with that id
	const { error, data } = await supabase
		.from('webScreens')
		.select('category')
		.eq('id', id);

	if (error) {
		console.log(error);
	}

	const { category } = data[0];
	const screensData = await getRelatedWebScreensByCategory(category, id); //get brands that are in the same category as the brand with the id viewed

	return screensData; //this can either be an empty array or an array of screens
};

export const getAllScreensByID = async (id: string | string[], page) => {
	let limit = 29;
	let limitMaxRange = page * limit;
	let limitMinRange = page * limit - limit;

	const { data, error } = await supabase
		.from('screenImages')
		.select('*')
		.order('url', { ascending: true })
		.range(limitMinRange, limitMaxRange)
		.eq('screenId', id);

	return data;
};

export const getAllFlows = async () => {
	let { data, error } = await supabase
		.from('Products')
		.select('*')
		.order('category', { ascending: true });
	if (error) console.log(error);
	return data;
};

export const updateScreenFlow = async (flowID, screenshotID) => {
	const { data, error } = await supabase
		.from('screenImages')
		.update({ flowid: flowID })
		.eq('id', screenshotID);
};

export const deleteScreenFlow = async (screenshotID) => {
	const { data, error } = await supabase
		.from('screenImages')
		.update({ flowid: null })
		.eq('id', screenshotID);
};

export const updateCategoryComponent = async (arr, screenshotID) => {
	const { data, error } = await supabase
		.from('screenImages')
		.update({ componentCategory: arr })
		.eq('id', screenshotID);

	if (error) console.log(error);
};

//get brand and screenshot count per onboarding.
export const BrandCountandScreensCount = async (flowCategory) => {
	flowCategory =
		flowCategory[0].charAt(0).toUpperCase() + flowCategory.slice(1);
	const { count: brandCount, error } = await supabase
		.from('screenshot_flowsss')
		.select('*', { count: 'exact', head: true })
		.eq('category', flowCategory);

	const { count: screenCount, error: screenCountError } = await supabase
		.from('screenshot_flbodw')
		.select('*', { count: 'exact', head: true })
		.eq('category', flowCategory);
	return {
		brandCount,
		screenCount,
	};
};

export const getBrandsforFlow = async (flowCategory, payingUser = false) => {
	flowCategory =
		flowCategory[0].charAt(0).toUpperCase() + flowCategory.slice(1);

	let queryObject = supabase
		.from('screenshot_flowsss')
		.select('name,logo')
		.eq('category', flowCategory);

	if (!payingUser) {
		queryObject = queryObject.limit(3);
	}
	const { data, error } = await queryObject;
	return data;
};

export const getScreenTypes = async () => {

	let queryObject = supabase
		.from('screentypes_with_ibbbmages')
		.select('*')
		.gte('image_count', 4)
		.order('category', { ascending: true })
	
	const { data, error } = await queryObject;
	return data;
};

export const getFlows = async (flowCategory, payingUser = false) => {
	const data = await getBrandsforFlow(flowCategory, payingUser);

	const datapack = Promise.all(
		data?.map(async (flow) => {
			const { data, error } = await supabase
				.from('screenshot_flbodw')
				.select('url')
				.eq('category', flowCategory)
				.eq('name', flow.name)
				.limit(2);
			return { name: flow.name, logo: flow.logo, preview: data };
		})
	);

	return await datapack;
};

export const getCategoryFlowForBRand = async (
	flowCategory,
	brand,
	strip 
) => {
	flowCategory =
		flowCategory[0].charAt(0).toUpperCase() + flowCategory.slice(1);
	brand = brand[0].charAt(0).toUpperCase() + brand.slice(1);

	//get the total number of screenshots per flow
	const { count, error: countError } = await supabase
		.from('screenshot_flbodw')
		.select('url', { count: 'exact', head: true })
		.eq('category', flowCategory)
		.eq('name', brand);

	let query = supabase
		.from('screenshot_flbodw')
		.select('url')
		.eq('category', flowCategory)
		.eq('name', brand);

	//for an unpaying user only one screenshot per flow is returned
	if (strip) {
		query = query.limit(1);
	}

	const { data: flows, error } = await query;

	return { flows, count };
};

// //    get unique bookmark names in single bookmark
// export async function getAllUniqueComponents() {
// 	const { data, error } = await supabase
// 		.from('screenImages')
// 		.select('componentCategory');
// console.log(data)

// 	//gets unique names in the db
// 	const result = data?.map((object) => object.componentCategory);
// 	console.log(result)
// 	const uniqueResult = Array.from(new Set(result));
// 	return uniqueResult;
// }

export const getScreenShotsByElement = async (element, payingUser = false) => {
	const arr = element?.split(' ');

	for (var i = 0; i < arr.length; i++) {
		arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
	}

	const str2 = arr.join(' ');

	const queryObject = supabase.from('screenImages');

	const { count, error: countError } = await queryObject
		.select('url', { count: 'exact', head: true })
		.contains('componentCategory', [str2]);

	const queryObjectForData = queryObject
		.select('url')
		.contains('componentCategory', [str2]);

	if (!payingUser) {
		queryObjectForData.limit(4);
	}

	const { data, error } = await queryObjectForData;

	return { data, count };
};

export const getScreenShotsByType = async (element, payingUser = false) => {


	const str2 = element;

	const queryObject = supabase.from('screenImages');

	const { count, error: countError } = await queryObject
		.select('url', { count: 'exact', head: true })
		.contains('screenCategory', [str2]);

	const queryObjectForData = queryObject
		.select('url')
		.contains('screenCategory', [str2]);

	if (!payingUser) {
		queryObjectForData.limit(4);
	}

	const { data, error } = await queryObjectForData;

	return { data, count };
};

export const getAppExtractedText = async (element,id, payingUser = false) => {
	
	const str2 = element.toLowerCase()

	const queryObject = supabase.from('screenImages');

	const { count, error: countError } = await queryObject
		.select('url', { count: 'exact', head: true })
		.eq('screenId', id)
		.contains('imagetext', [str2]);

	const queryObjectForData = queryObject
		.select('url')
		.eq('screenId', id)
		.contains('imagetext', [str2]);

	if (!payingUser) {
		queryObjectForData.limit(2);
	}

	const { data, error } = await queryObjectForData;

	return { data, count };
};

export const getWebAppExtractedText = async (element,id, payingUser = false) => {
	
	const str2 = element.toLowerCase()

	const queryObject = supabase.from('webScreenImages');

	const { count, error: countError } = await queryObject
		.select('url', { count: 'exact', head: true })
		.eq('screenId', id)
		.contains('imagetext', [str2]);

	const queryObjectForData = queryObject
		.select('url')
		.eq('screenId', id)
		.contains('imagetext', [str2]);

	if (!payingUser) {
		queryObjectForData.limit(2);
	}

	const { data, error } = await queryObjectForData;

	return { data, count };
};

//get all flows of all brands
export async function getAllBrandFlows() {
	const { data, error } = await supabase
		.from('screenshot_flowsss')
		.select('name,category')
		.neq('category', null);
	return data;
}

//get flows of a brand
export async function getBrandFlows(brand) {
	const { data, error } = await supabase
		.from('screenshot_flowsss')
		.select('category')
		//used ilike for case insensitive instead of eq
		.ilike('name', brand)
		.neq('category', null);
	return data;
}


export async function getWebBrandFlows(brandID) {
	const { data, error } = await supabase
		.from('webScreenImages')
		.select('screenCategory')
		.eq('screenId', brandID)
		.order('screenCategory', { ascending: true })

	//gets unique names in the db
	const result = data?.map((object) => object.screenCategory);
	const uniqueResult = Array.from(new Set(result));
	return uniqueResult;
}


//get all element categories of a brands
export async function getBrandElements(brandID) {
	let finalArr = [];
	const { data, error } = await supabase
		.from('screenImages')
		.select('componentCategory')
		.eq('screenId', brandID);
	if (data) {
		data?.map((item) => {
			finalArr = [...finalArr, ...item.componentCategory];
		});
	}

	return new Set(finalArr);
}

//get all screens for a given brand
export async function getAllScreensPerBrand(brandID) {
	const { data, error } = await supabase
		.from('screenImages')
		.select('*')
		.order('order', { ascending: true })
		.eq('screenId', brandID);
	return data;
}
